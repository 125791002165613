<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        div.search-overlay
        
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{ $t('studentHome.studentPanel') }}
                    
                    div
                        nav.breadcrumb-one(aria-label="breadcrumb")
                            ol.breadcrumb
                                li.breadcrumb-item
                                    a(href="javascript:void(0);")  {{ $t('studentHome.studentPanel') }} 
                                li.breadcrumb-item.active(aria-current="page") {{ $t('studentHome.myDashboard') }} 

                div.row.mt-5
                    router-link.infobox-4.widget.bg-white(href="#" to="/student/MyExams")
                        div.info-icon
                            svg.bi.bi-ui-checks-grid(xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16")
                                path(d="M2 10h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1zm9-9h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zm0 9a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-3zm0-10a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-3zM2 9a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H2zm7 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-3zM0 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.354.854a.5.5 0 1 0-.708-.708L3 3.793l-.646-.647a.5.5 0 1 0-.708.708l1 1a.5.5 0 0 0 .708 0l2-2z")
                        h5.info-heading {{ $t('studentHome.myExams') }} 
                                   
                    router-link.infobox-4.widget.bg-white(href="#" to="/student/badges")                                  
                        div
                            div.info-icon
                                svg.feather.feather-award(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                    circle(cx="12" cy="8" r="7")
                                    polyline(points="8.21 13.89 7 23 12 20 17 23 15.79 13.88")
                            h5.info-heading {{ $t('studentHome.myBadges') }} 
                                    
                    router-link.infobox-4.widget.bg-white(href="#" to="/student/myCertificates")
                        div
                            div.info-icon
                                svg.bi.bi-patch-check(xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16")
                                    path(fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z")
                                    path(d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z")
                            h5.info-heading {{ $t('studentHome.myCertificates') }} 
                                    
                    a.infobox-4.widget.bg-white(href="#" @click="validateExams()")
                        div
                            div.info-icon
                                svg.feather.feather-power(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                    path(d="M18.36 6.64a9 9 0 1 1-12.73 0")
                                    line(x1="12" y1="2" x2="12" y2="12")
                            h5.info-heading {{ $t('studentHome.examActivate') }} 
            
            div.footer-wrapper
                div.footer-section.ml-4
                    p © 2021 
                        a(target="_blank" href="https://etciberoamerica.com/") ETC Iberoamérica
                            template , Todos los derechos reservados.
</template>

<script>
/* eslint-disable */
export default {
    name: 'Home',
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
        async validateExams(){
            await axios.get(apiURL + 'Student/userHasGroupExams?idPerson='+ localStorage.CertIdP, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                console.log(response)
                if( response.data.bIsCorrect == true) {
                    router.push("/student/availableExams")
                }
                else {
                    router.push("/student/ActivateExam")
                }

            } )
            .catch( (error)  => {
                console.log("error")
            } )            
        }
        
    },
    mounted() {
        this.checkPermisions(this.$router.currentRoute.path)
    }
}
</script>
